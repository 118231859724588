@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

@font-face {
  font-family: mont-bold;
  src: url(../src/assets/fonts/Mont-Bold.otf);
}

@font-face {
  font-family: mont-regular;
  src: url(../src/assets/fonts/Mont-Regular.otf);
}

@font-face {
  font-family: arial;
  src: url(../src/assets/fonts/Arial.TTF);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'mont-regular', sans-serif;
  /* font-weight: 500; */
  background-color: rgb(238, 240, 243) !important;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #807E7E;
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  background-color: #c3c3c3ef;
  border-radius: 20px;
}

.bg-blue-mega-imoveis {
  background-color: #0A21FF !important;
}

.bg-red-mega-imoveis {
  background-color: #E00501 !important;
}

.text-blue-mega-imoveis {
  color: #0A21FF !important;
}

.hover\:text-blue-mega-imoveis:hover {
  color: #0A21FF !important;
}

.border-blue-mega-imoveis {
  border-color: #0A21FF;
  /* border-color: rgba(0, 0, 0, var(--border-opacity)); */
}

.bg-blue-dark-mega-imoveis {
  background-color: #0717B3 !important;
}

.text-blue-dark-mega-imoveis {
  color: #0717B3 !important;
}

.border-blue-dark-mega-imoveis {
  border-color: #0717B3;
  /* border-color: rgba(0, 0, 0, var(--border-opacity)); */
}

.hover\:text-blue-dark-mega-imoveis:hover {
  color: #0717B3 !important;
}

.bg-gray-ligth-system-mega-imoveis {
  background-color: #E6E6E6;
}

.hover\:bg-gray-ligth-system-mega-imoveis:hover {
  background-color: #E6E6E6;
}

.text-gray-ligth-system-mega-imoveis {
  color: #E6E6E6;
}

.bg-gray-system-mega-imoveis {
  background-color: #807E7E;
}

.text-gray-system-mega-imoveis {
  color: #807E7E;
}

.border-gray-system-mega-imoveis {
  border-color: #807E7E;
}

.bg-white-system-mega-imoveis {
  background-color: #F9F9F9;
}

.bg-white2-mega-imoveis {
  background-color: #FDFDFD;
}

.bg-white3-mega-imoveis {
  background-color: #FFF;
}


.scrollbar-megaImoveis::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-megaImoveis::-webkit-scrollbar-thumb {
  background-color: #0717B3;
  border-radius: 35px;
}

.scrollbar-megaImoveis::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-megaImoveis::-webkit-scrollbar-track-piece {
  background-color: #FDFDFD;
}

.font-mont-bold{
  font-family: 'mont-bold', sans-serif;
}

.font-arial{
  font-family: 'Arial', sans-serif;
}

.card-content {
  background: #FDFDFD;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.accent-green {
  accent-color: #059238;
}

.accent-gray {
  accent-color: #d6d6d6;
}

.accent-red {
  accent-color: #FF3B3B;
}

.to-blue-mega-imoveis {
  --gradient-to-color: #265BFF;
}

.from-blue-mega-imoveis {
  --gradient-from-color: #002087;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}


