.sem-consultas {
    text-align-last: center;
    font-size: 2rem;
    color: rgba(128, 126, 126, 1);
    margin-top: 8rem;
}

.colapse-consulta {
    background: linear-gradient(to right, #002087, #265BFF);
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 2s;
}

.consulta-none {
    display: none;
}

.consulta-expandido {
    display: block;
    padding: 2rem;
    transition: 1s;
}

.form-consulta-flex {
    display: flex;
}

.consulta-grande-expandido {
    background: #FDFDFD;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: 30rem;
    transition: 1s;
    overflow: hidden;
}

.consulta-nao-expandido {
    height: 4rem;
    transition: 1s;
}

.box-buttom {
    text-align: -webkit-center;
}

.form-consulta > .css-b62m3t-container > .css-1s2u09g-control {
    height: 3rem;
    border-color: rgba(167, 167, 167, 1);
}

.form-consulta > .css-b62m3t-container > .css-1pahdxg-control {
    height: 3rem;
    border-color: rgba(167, 167, 167, 1) !important;
    outline: none !important;
    box-shadow: none;
}

.form-consulta-status {
    width: 24rem;
}

.form-consulta.mt-2.flex.status-cancelados {
    justify-content: space-between;
}